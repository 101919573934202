export enum CORBAN_SUBMODULES {
  CONFIG = 'config',
  CEP = 'cep',
  CP = 'cp',
  CPUBLICO = 'cpublico',
}

export enum SALESCHANNEL_SUBMODULES {
  CP = 'CP',
  CDC = 'CDC',
  CDV = 'CDCVEICULO',
  PJ = 'PJ',
}

export enum COMPANY_SUBMODULES {
  SUPERVISOR = 'SUPERVISOR',
}
// Adicionar outros submodulos caso tenha a necessidade
export type TSubModules = CORBAN_SUBMODULES | SALESCHANNEL_SUBMODULES | COMPANY_SUBMODULES
