import { ENTITIES, ICompany, IEntity } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.DEFAULT)
export const DefaultEntity: IEntity = {
  id: ENTITIES.DEFAULT,
  name: 'CreditoFolha',
  path: '',
  theme: theme,
  materialTheme: materialTheme,
  logo: logo,
  permissions: [],
  component: DefaultComponent,
  route: Routes,
  company: {} as ICompany,
}

export default DefaultEntity
