import { IEntity, ENTITIES, ICompany } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'
import { authRequired } from 'permissions'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.COMPANY)
export const CompanyEntity: IEntity = {
  id: ENTITIES.COMPANY,
  name: 'Empresa',
  path: '/company',
  theme,
  materialTheme,
  logo,
  permissions: [authRequired],
  component: DefaultComponent,
  route: Routes,
  company: {} as ICompany,
}

export default CompanyEntity
