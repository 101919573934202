import { IPermission } from 'interfaces'
import authRequired from './authRequired'
import hasPermission from './hasPermission'
import cardAuthRequired from './cardAuthRequired'

export { authRequired, hasPermission, cardAuthRequired }

interface PermissionList {
  [propName: string]: IPermission
}

export default {
  authRequired,
  cardAuthRequired,
} as PermissionList
