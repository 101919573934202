import { IPermission } from 'interfaces'

export const hasSubmodulePermission = (subModuleName: string[]): IPermission => ({
  validate: function () {
    const { entity } = this

    const hasPermission = subModuleName.includes(entity?.module?.subModule || '')
    if (hasPermission) {
      return true
    }
    return false
  },
  action: function () {
    return null
  },
})

export default hasSubmodulePermission
