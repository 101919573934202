import { IEntity, ENTITIES, ICompany } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'
import { authRequired } from 'permissions'
import { routeCodes } from 'constants/routes'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.CORBAN)
export const CorbanCEPEntity: IEntity = {
  id: ENTITIES.CORBAN,
  name: 'corban-cep',
  path: routeCodes.ENTITY.CORBAN,
  theme: theme,
  materialTheme: materialTheme,
  logo: logo,
  permissions: [authRequired],
  component: DefaultComponent,
  route: Routes,
  company: {} as ICompany,
}

export default CorbanCEPEntity
