import * as React from 'react'

function BancoGenialSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 200 74'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M31.1087 0C29.2432 0 27.7109 1.53231 27.7109 3.39772V3.86406C27.7109 5.72948 29.2432 7.26178 31.1087 7.26178H37.4377V13.6575C37.4377 15.5229 38.97 17.0552 40.8355 17.0552H41.3018C43.1672 17.0552 44.6995 15.5229 44.6995 13.6575V7.32841V3.46435C44.6995 1.59893 43.2338 0.133248 41.4351 0.066626H41.3684H41.3018H37.4377H31.1087V0Z'
        fill='#4AA9FC'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M63.2229 44.7033C66.6873 44.7033 70.2848 43.4374 72.8165 41.1057C73.0163 40.9058 73.3494 40.8392 73.6159 40.9058L79.6785 42.5713C80.2115 42.7046 80.478 43.3042 80.1449 43.7706C76.7472 49.1003 69.6186 52.2982 63.1563 52.2982C52.2303 52.2982 43.8359 43.4374 43.8359 32.9778C43.8359 22.0518 52.63 13.5908 63.1563 13.5908C72.4834 13.5908 80.4114 20.3863 82.0769 29.3136C82.4766 31.3123 82.6765 33.3109 82.3434 35.3762C82.2768 35.7759 81.9437 36.0424 81.5439 36.0424H52.7633C52.5634 36.0424 52.3635 36.2423 52.4302 36.5088C53.5627 41.7719 57.9598 44.7033 63.2229 44.7033ZM52.4302 29.1803C52.3635 29.3802 52.5634 29.6467 52.7633 29.6467H73.3494C73.6159 29.6467 73.7492 29.4468 73.6825 29.1803C72.6166 24.8499 68.7525 21.1191 63.0897 21.1191C57.4934 21.1191 53.4961 24.8499 52.4302 29.1803Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M63.2229 44.7033C66.6873 44.7033 70.2848 43.4374 72.8165 41.1057C73.0163 40.9058 73.3494 40.8392 73.6159 40.9058L79.6785 42.5713C80.2115 42.7046 80.478 43.3042 80.1449 43.7706C76.7472 49.1003 69.6186 52.2982 63.1563 52.2982C52.2303 52.2982 43.8359 43.4374 43.8359 32.9778C43.8359 22.0518 52.63 13.5908 63.1563 13.5908C72.4834 13.5908 80.4114 20.3863 82.0769 29.3136C82.4766 31.3123 82.6765 33.3109 82.3434 35.3762C82.2768 35.7759 81.9437 36.0424 81.5439 36.0424H52.7633C52.5634 36.0424 52.3635 36.2423 52.4302 36.5088C53.5627 41.7719 57.9598 44.7033 63.2229 44.7033ZM52.4302 29.1803C52.3635 29.3802 52.5634 29.6467 52.7633 29.6467H73.3494C73.6159 29.6467 73.7492 29.4468 73.6825 29.1803C72.6166 24.8499 68.7525 21.1191 63.0897 21.1191C57.4934 21.1191 53.4961 24.8499 52.4302 29.1803Z'
        fill='#0D1D96'
      />
      <path
        d='M123.386 28.4472V50.8322C123.386 51.2985 122.986 51.6983 122.52 51.6983H115.591C115.125 51.6983 114.725 51.2985 114.725 50.8322V30.9122C114.725 25.1828 110.661 21.5186 105.931 21.5186C100.001 21.5186 96.1374 26.3153 96.1374 32.3113V50.7656C96.1374 51.2319 95.7377 51.6317 95.2713 51.6317H88.3426C87.8763 51.6317 87.4766 51.2319 87.4766 50.7656V14.9896C87.4766 14.5233 87.8763 14.1235 88.3426 14.1235H95.2713C95.7377 14.1235 96.1374 14.5233 96.1374 14.9896V17.388C96.1374 17.7211 96.5371 17.8544 96.737 17.6545C98.3359 15.8557 102.8 13.5239 107.796 13.5239C115.924 13.5239 123.386 19.1202 123.386 28.4472Z'
        fill='white'
      />
      <path
        d='M123.386 28.4472V50.8322C123.386 51.2985 122.986 51.6983 122.52 51.6983H115.591C115.125 51.6983 114.725 51.2985 114.725 50.8322V30.9122C114.725 25.1828 110.661 21.5186 105.931 21.5186C100.001 21.5186 96.1374 26.3153 96.1374 32.3113V50.7656C96.1374 51.2319 95.7377 51.6317 95.2713 51.6317H88.3426C87.8763 51.6317 87.4766 51.2319 87.4766 50.7656V14.9896C87.4766 14.5233 87.8763 14.1235 88.3426 14.1235H95.2713C95.7377 14.1235 96.1374 14.5233 96.1374 14.9896V17.388C96.1374 17.7211 96.5371 17.8544 96.737 17.6545C98.3359 15.8557 102.8 13.5239 107.796 13.5239C115.924 13.5239 123.386 19.1202 123.386 28.4472Z'
        fill='#0D1D96'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M176.211 51.6983C175.744 51.6983 175.345 51.2985 175.345 50.8322V48.6337C175.345 48.3006 174.945 48.1673 174.745 48.3672C172.08 51.0987 168.283 52.2313 163.553 52.2313C153.359 52.2313 144.898 43.3705 144.898 32.9109C144.898 21.9849 153.359 13.5239 163.553 13.5239C168.283 13.5239 172.08 14.9896 174.745 17.7877C174.945 18.0542 175.345 17.8543 175.345 17.5212V14.9896C175.345 14.5233 175.744 14.1235 176.211 14.1235H183.139C183.606 14.1235 184.006 14.5233 184.006 14.9896V50.7656C184.006 51.2319 183.606 51.6317 183.139 51.6317H176.211V51.6983ZM152.96 32.9109C152.96 39.1734 157.89 44.4365 164.419 44.4365C170.681 44.4365 175.878 39.1734 175.878 32.9109C175.878 26.6484 170.748 21.3187 164.419 21.3187C157.89 21.3187 152.96 26.7151 152.96 32.9109Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M176.211 51.6983C175.744 51.6983 175.345 51.2985 175.345 50.8322V48.6337C175.345 48.3006 174.945 48.1673 174.745 48.3672C172.08 51.0987 168.283 52.2313 163.553 52.2313C153.359 52.2313 144.898 43.3705 144.898 32.9109C144.898 21.9849 153.359 13.5239 163.553 13.5239C168.283 13.5239 172.08 14.9896 174.745 17.7877C174.945 18.0542 175.345 17.8543 175.345 17.5212V14.9896C175.345 14.5233 175.744 14.1235 176.211 14.1235H183.139C183.606 14.1235 184.006 14.5233 184.006 14.9896V50.7656C184.006 51.2319 183.606 51.6317 183.139 51.6317H176.211V51.6983ZM152.96 32.9109C152.96 39.1734 157.89 44.4365 164.419 44.4365C170.681 44.4365 175.878 39.1734 175.878 32.9109C175.878 26.6484 170.748 21.3187 164.419 21.3187C157.89 21.3187 152.96 26.7151 152.96 32.9109Z'
        fill='#0D1D96'
      />
      <path
        d='M138.907 15.0565C138.907 14.5902 138.507 14.1904 138.041 14.1904H131.046C130.579 14.1904 130.18 14.5902 130.18 15.0565V50.8325C130.18 51.2988 130.579 51.6986 131.046 51.6986H137.974C138.441 51.6986 138.841 51.2988 138.841 50.8325V15.0565H138.907Z'
        fill='white'
      />
      <path
        d='M138.907 15.0565C138.907 14.5902 138.507 14.1904 138.041 14.1904H131.046C130.579 14.1904 130.18 14.5902 130.18 15.0565V50.8325C130.18 51.2988 130.579 51.6986 131.046 51.6986H137.974C138.441 51.6986 138.841 51.2988 138.841 50.8325V15.0565H138.907Z'
        fill='#0D1D96'
      />
      <path
        d='M191.936 51.6986C191.47 51.6986 191.07 51.2989 191.07 50.8325V0.866077C191.07 0.399723 191.47 0 191.936 0H198.865C199.331 0 199.731 0.399723 199.731 0.866077V50.8325C199.731 51.2989 199.331 51.6986 198.865 51.6986H191.936Z'
        fill='white'
      />
      <path
        d='M191.936 51.6986C191.47 51.6986 191.07 51.2989 191.07 50.8325V0.866077C191.07 0.399723 191.47 0 191.936 0H198.865C199.331 0 199.731 0.399723 199.731 0.866077V50.8325C199.731 51.2989 199.331 51.6986 198.865 51.6986H191.936Z'
        fill='#0D1D96'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M27.5815 50.8325C27.4482 50.7658 27.315 50.6992 27.1817 50.7659C24.85 51.7652 22.2517 52.3648 19.5202 52.3648C8.79409 52.3648 0.0666158 43.7039 0.0666158 32.9778C0.0666158 22.2517 8.79409 13.5908 19.5202 13.5908C30.2463 13.5908 38.9738 22.2517 38.9738 32.9778C38.9738 37.7746 37.2416 42.105 34.3769 45.5027C34.2437 45.636 34.2437 45.8358 34.3769 45.9691C37.2416 48.8338 38.9072 52.5646 38.9072 56.6286C38.9072 65.7558 30.2463 73.2174 19.4536 73.2174C8.66085 73.2174 0 65.7558 0 56.6286C0 56.4287 0.133244 56.2955 0.33311 56.2955H8.12787C8.32774 56.2955 8.46098 56.4287 8.46098 56.6286C8.46098 59.027 9.72679 61.1589 11.7254 62.6245C13.7241 64.1569 16.4556 65.0896 19.4536 65.0896C22.4516 65.0896 25.1831 64.1569 27.1817 62.6245C29.1804 61.0922 30.4462 58.9603 30.4462 56.6286C30.4462 54.3634 29.3803 52.3648 27.5815 50.8325ZM19.5202 44.6366C25.9825 44.6366 31.1791 39.4401 31.1791 32.9778C31.1791 26.5155 25.9825 21.319 19.5202 21.319C13.0579 21.319 7.86139 26.5155 7.86139 32.9778C7.86139 39.3735 13.0579 44.6366 19.5202 44.6366Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M27.5815 50.8325C27.4482 50.7658 27.315 50.6992 27.1817 50.7659C24.85 51.7652 22.2517 52.3648 19.5202 52.3648C8.79409 52.3648 0.0666158 43.7039 0.0666158 32.9778C0.0666158 22.2517 8.79409 13.5908 19.5202 13.5908C30.2463 13.5908 38.9738 22.2517 38.9738 32.9778C38.9738 37.7746 37.2416 42.105 34.3769 45.5027C34.2437 45.636 34.2437 45.8358 34.3769 45.9691C37.2416 48.8338 38.9072 52.5646 38.9072 56.6286C38.9072 65.7558 30.2463 73.2174 19.4536 73.2174C8.66085 73.2174 0 65.7558 0 56.6286C0 56.4287 0.133244 56.2955 0.33311 56.2955H8.12787C8.32774 56.2955 8.46098 56.4287 8.46098 56.6286C8.46098 59.027 9.72679 61.1589 11.7254 62.6245C13.7241 64.1569 16.4556 65.0896 19.4536 65.0896C22.4516 65.0896 25.1831 64.1569 27.1817 62.6245C29.1804 61.0922 30.4462 58.9603 30.4462 56.6286C30.4462 54.3634 29.3803 52.3648 27.5815 50.8325ZM19.5202 44.6366C25.9825 44.6366 31.1791 39.4401 31.1791 32.9778C31.1791 26.5155 25.9825 21.319 19.5202 21.319C13.0579 21.319 7.86139 26.5155 7.86139 32.9778C7.86139 39.3735 13.0579 44.6366 19.5202 44.6366Z'
        fill='#0D1D96'
      />
      <path d='M49.1641 73.2177V61.7588H50.8962V73.2177H49.1641Z' fill='white' />
      <path d='M49.1641 73.2177V61.7588H50.8962V73.2177H49.1641Z' fill='#0D1D96' />
      <path
        d='M62.8866 73.2172V66.4883C62.8866 65.489 62.5534 64.6896 61.9538 64.0233C61.3542 63.3571 60.5548 63.0906 59.6221 63.0906C58.9559 63.0906 58.3563 63.2239 57.8899 63.557C57.3569 63.8235 56.9572 64.2232 56.6907 64.7562C56.4242 65.2892 56.2244 65.8221 56.2244 66.4883L55.4249 66.022C55.4249 65.1559 55.6248 64.3565 56.0245 63.6902C56.4242 63.024 56.9572 62.491 57.69 62.0913C58.4229 61.6916 59.1557 61.4917 60.0884 61.4917C60.9545 61.4917 61.754 61.6916 62.4202 62.1579C63.0864 62.6243 63.6194 63.1573 64.0191 63.8901C64.4188 64.6229 64.6187 65.3558 64.6187 66.2219V73.2838H62.8866V73.2172ZM54.4922 73.2172V61.7582H56.2244V73.2172H54.4922Z'
        fill='white'
      />
      <path
        d='M62.8866 73.2172V66.4883C62.8866 65.489 62.5534 64.6896 61.9538 64.0233C61.3542 63.3571 60.5548 63.0906 59.6221 63.0906C58.9559 63.0906 58.3563 63.2239 57.8899 63.557C57.3569 63.8235 56.9572 64.2232 56.6907 64.7562C56.4242 65.2892 56.2244 65.8221 56.2244 66.4883L55.4249 66.022C55.4249 65.1559 55.6248 64.3565 56.0245 63.6902C56.4242 63.024 56.9572 62.491 57.69 62.0913C58.4229 61.6916 59.1557 61.4917 60.0884 61.4917C60.9545 61.4917 61.754 61.6916 62.4202 62.1579C63.0864 62.6243 63.6194 63.1573 64.0191 63.8901C64.4188 64.6229 64.6187 65.3558 64.6187 66.2219V73.2838H62.8866V73.2172ZM54.4922 73.2172V61.7582H56.2244V73.2172H54.4922Z'
        fill='#0D1D96'
      />
      <path
        d='M71.8803 73.2177L66.6172 61.7588H68.5492L73.0129 71.8187H71.9469L76.4106 61.7588H78.276L72.9463 73.2177H71.8803Z'
        fill='white'
      />
      <path
        d='M71.8803 73.2177L66.6172 61.7588H68.5492L73.0129 71.8187H71.9469L76.4106 61.7588H78.276L72.9463 73.2177H71.8803Z'
        fill='#0D1D96'
      />
      <path
        d='M85.3401 73.4839C84.2076 73.4839 83.1416 73.2174 82.2089 72.6845C81.2762 72.1515 80.5434 71.4186 80.0104 70.5526C79.4774 69.6198 79.2109 68.6205 79.2109 67.4879C79.2109 66.3554 79.4774 65.356 80.0104 64.49C80.5434 63.5572 81.2096 62.891 82.1423 62.3581C83.0084 61.8251 84.0077 61.5586 85.1403 61.5586C86.2062 61.5586 87.0723 61.8251 87.8718 62.2914C88.6712 62.7578 89.3375 63.424 89.8038 64.2901C90.2702 65.0896 90.5367 66.0889 90.5367 67.1548C90.5367 67.2881 90.5367 67.4213 90.5367 67.6212C90.5367 67.7544 90.47 67.9543 90.47 68.2208H80.5434V66.6219H89.5373L88.9377 67.1548C88.9377 66.2887 88.8045 65.5559 88.4714 64.9563C88.2049 64.3567 87.7385 63.8904 87.2056 63.5573C86.6726 63.2241 86.0064 63.0243 85.2069 63.0243C84.4074 63.0243 83.6746 63.2241 83.0084 63.5573C82.4088 63.8904 81.8758 64.4233 81.5427 65.0896C81.2096 65.7558 81.0097 66.4886 81.0097 67.3547C81.0097 68.2208 81.2096 69.0203 81.5427 69.6865C81.9424 70.3527 82.4088 70.8857 83.075 71.2188C83.7412 71.6185 84.4741 71.7518 85.3401 71.7518C86.0064 71.7518 86.6726 71.6185 87.2722 71.4186C87.8718 71.1522 88.3381 70.7524 88.7379 70.2861L89.8038 71.4186C89.2708 72.0849 88.6046 72.5512 87.8052 72.8843C87.0723 73.284 86.2728 73.4839 85.3401 73.4839Z'
        fill='white'
      />
      <path
        d='M85.3401 73.4839C84.2076 73.4839 83.1416 73.2174 82.2089 72.6845C81.2762 72.1515 80.5434 71.4186 80.0104 70.5526C79.4774 69.6198 79.2109 68.6205 79.2109 67.4879C79.2109 66.3554 79.4774 65.356 80.0104 64.49C80.5434 63.5572 81.2096 62.891 82.1423 62.3581C83.0084 61.8251 84.0077 61.5586 85.1403 61.5586C86.2062 61.5586 87.0723 61.8251 87.8718 62.2914C88.6712 62.7578 89.3375 63.424 89.8038 64.2901C90.2702 65.0896 90.5367 66.0889 90.5367 67.1548C90.5367 67.2881 90.5367 67.4213 90.5367 67.6212C90.5367 67.7544 90.47 67.9543 90.47 68.2208H80.5434V66.6219H89.5373L88.9377 67.1548C88.9377 66.2887 88.8045 65.5559 88.4714 64.9563C88.2049 64.3567 87.7385 63.8904 87.2056 63.5573C86.6726 63.2241 86.0064 63.0243 85.2069 63.0243C84.4074 63.0243 83.6746 63.2241 83.0084 63.5573C82.4088 63.8904 81.8758 64.4233 81.5427 65.0896C81.2096 65.7558 81.0097 66.4886 81.0097 67.3547C81.0097 68.2208 81.2096 69.0203 81.5427 69.6865C81.9424 70.3527 82.4088 70.8857 83.075 71.2188C83.7412 71.6185 84.4741 71.7518 85.3401 71.7518C86.0064 71.7518 86.6726 71.6185 87.2722 71.4186C87.8718 71.1522 88.3381 70.7524 88.7379 70.2861L89.8038 71.4186C89.2708 72.0849 88.6046 72.5512 87.8052 72.8843C87.0723 73.284 86.2728 73.4839 85.3401 73.4839Z'
        fill='#0D1D96'
      />
      <path
        d='M96.8037 73.4839C96.2041 73.4839 95.6045 73.4173 95.0049 73.2174C94.4053 73.0176 93.939 72.8177 93.4726 72.4846C93.0063 72.1515 92.6065 71.8184 92.2734 71.4186L93.3394 70.2861C93.8057 70.819 94.3387 71.2854 94.8717 71.5519C95.4713 71.8184 96.1375 71.9516 96.8037 71.9516C97.5366 71.9516 98.1362 71.8184 98.6025 71.4853C99.0689 71.2188 99.2687 70.7524 99.2687 70.2861C99.2687 69.8197 99.1355 69.42 98.8024 69.1535C98.4693 68.887 98.0695 68.6871 97.6032 68.4873C97.1368 68.354 96.6039 68.1542 96.0709 68.0209C95.5379 67.8877 95.005 67.6878 94.5386 67.4213C94.0722 67.1548 93.6725 66.8217 93.3394 66.422C93.0729 66.0223 92.8731 65.4227 92.8731 64.7565C92.8731 64.0902 93.0063 63.5573 93.3394 63.0909C93.6725 62.6246 94.1389 62.2248 94.7385 61.9583C95.3381 61.6918 96.0709 61.5586 96.8704 61.5586C97.7364 61.5586 98.5359 61.6918 99.2021 62.025C99.935 62.3581 100.468 62.7578 100.868 63.424L99.8017 64.49C99.4686 64.0236 99.0023 63.6905 98.5359 63.4906C98.0029 63.2241 97.47 63.1575 96.8037 63.1575C96.0709 63.1575 95.5379 63.2908 95.1382 63.6239C94.7385 63.8904 94.6052 64.2901 94.6052 64.6898C94.6052 65.1562 94.7385 65.4893 95.0716 65.7558C95.4047 66.0223 95.7378 66.2221 96.2708 66.3554C96.7371 66.4886 97.2701 66.6885 97.8031 66.8217C98.336 66.955 98.869 67.1548 99.3354 67.4213C99.8017 67.6878 100.201 68.0209 100.535 68.4207C100.868 68.8204 101.001 69.42 101.001 70.1528C101.001 71.1521 100.601 72.0182 99.8684 72.6178C99.0689 73.1508 98.0696 73.4839 96.8037 73.4839Z'
        fill='white'
      />
      <path
        d='M96.8037 73.4839C96.2041 73.4839 95.6045 73.4173 95.0049 73.2174C94.4053 73.0176 93.939 72.8177 93.4726 72.4846C93.0063 72.1515 92.6065 71.8184 92.2734 71.4186L93.3394 70.2861C93.8057 70.819 94.3387 71.2854 94.8717 71.5519C95.4713 71.8184 96.1375 71.9516 96.8037 71.9516C97.5366 71.9516 98.1362 71.8184 98.6025 71.4853C99.0689 71.2188 99.2687 70.7524 99.2687 70.2861C99.2687 69.8197 99.1355 69.42 98.8024 69.1535C98.4693 68.887 98.0695 68.6871 97.6032 68.4873C97.1368 68.354 96.6039 68.1542 96.0709 68.0209C95.5379 67.8877 95.005 67.6878 94.5386 67.4213C94.0722 67.1548 93.6725 66.8217 93.3394 66.422C93.0729 66.0223 92.8731 65.4227 92.8731 64.7565C92.8731 64.0902 93.0063 63.5573 93.3394 63.0909C93.6725 62.6246 94.1389 62.2248 94.7385 61.9583C95.3381 61.6918 96.0709 61.5586 96.8704 61.5586C97.7364 61.5586 98.5359 61.6918 99.2021 62.025C99.935 62.3581 100.468 62.7578 100.868 63.424L99.8017 64.49C99.4686 64.0236 99.0023 63.6905 98.5359 63.4906C98.0029 63.2241 97.47 63.1575 96.8037 63.1575C96.0709 63.1575 95.5379 63.2908 95.1382 63.6239C94.7385 63.8904 94.6052 64.2901 94.6052 64.6898C94.6052 65.1562 94.7385 65.4893 95.0716 65.7558C95.4047 66.0223 95.7378 66.2221 96.2708 66.3554C96.7371 66.4886 97.2701 66.6885 97.8031 66.8217C98.336 66.955 98.869 67.1548 99.3354 67.4213C99.8017 67.6878 100.201 68.0209 100.535 68.4207C100.868 68.8204 101.001 69.42 101.001 70.1528C101.001 71.1521 100.601 72.0182 99.8684 72.6178C99.0689 73.1508 98.0696 73.4839 96.8037 73.4839Z'
        fill='#0D1D96'
      />
      <path
        d='M109.126 73.4837C108.46 73.4837 107.861 73.3504 107.328 73.0839C106.795 72.8174 106.395 72.3511 106.062 71.8181C105.729 71.2851 105.595 70.6189 105.595 69.8194V56.9614H107.328V69.7528C107.328 70.4191 107.527 70.952 107.861 71.3518C108.194 71.6849 108.66 71.8847 109.26 71.8847C109.659 71.8847 109.992 71.8181 110.259 71.6849C110.525 71.5516 110.859 71.3518 111.058 71.0853L112.124 72.1512C111.725 72.6176 111.325 72.9507 110.859 73.1505C110.326 73.3504 109.793 73.4837 109.126 73.4837ZM102.664 63.3571V61.7582H111.725V63.3571H102.664Z'
        fill='white'
      />
      <path
        d='M109.126 73.4837C108.46 73.4837 107.861 73.3504 107.328 73.0839C106.795 72.8174 106.395 72.3511 106.062 71.8181C105.729 71.2851 105.595 70.6189 105.595 69.8194V56.9614H107.328V69.7528C107.328 70.4191 107.527 70.952 107.861 71.3518C108.194 71.6849 108.66 71.8847 109.26 71.8847C109.659 71.8847 109.992 71.8181 110.259 71.6849C110.525 71.5516 110.859 71.3518 111.058 71.0853L112.124 72.1512C111.725 72.6176 111.325 72.9507 110.859 73.1505C110.326 73.3504 109.793 73.4837 109.126 73.4837ZM102.664 63.3571V61.7582H111.725V63.3571H102.664Z'
        fill='#0D1D96'
      />
      <path d='M114.656 73.2177V61.7588H116.388V73.2177H114.656Z' fill='white' />
      <path d='M114.656 73.2177V61.7588H116.388V73.2177H114.656Z' fill='#0D1D96' />
      <path
        d='M119.922 73.2172V61.7582H121.654V73.2172H119.922ZM127.85 73.2172V66.1552C127.85 65.2225 127.583 64.4897 126.984 63.8901C126.384 63.2905 125.718 63.024 124.852 63.024C124.252 63.024 123.719 63.1573 123.253 63.4238C122.787 63.6902 122.387 64.0233 122.12 64.4897C121.854 64.956 121.721 65.489 121.721 66.1552L120.921 65.8221C120.921 64.956 121.121 64.1566 121.521 63.557C121.921 62.8908 122.454 62.4244 123.053 62.0247C123.719 61.6916 124.452 61.4917 125.318 61.4917C126.118 61.4917 126.851 61.6916 127.517 62.0247C128.183 62.3578 128.649 62.8908 129.049 63.557C129.449 64.2232 129.649 64.956 129.649 65.8887V73.2172H127.85ZM135.711 73.2172V66.1552C135.711 65.2225 135.445 64.4897 134.845 63.8901C134.246 63.2905 133.579 63.024 132.647 63.024C132.047 63.024 131.581 63.1573 131.048 63.4238C130.581 63.6902 130.182 64.0233 129.915 64.4897C129.649 64.956 129.515 65.489 129.515 66.1552L128.516 65.8221C128.583 64.956 128.783 64.1566 129.182 63.557C129.582 62.8908 130.182 62.4244 130.848 62.0247C131.514 61.6916 132.247 61.4917 133.113 61.4917C133.912 61.4917 134.645 61.6916 135.312 62.0247C135.978 62.3578 136.511 62.8908 136.844 63.557C137.244 64.2232 137.443 64.956 137.443 65.8887V73.2172H135.711Z'
        fill='white'
      />
      <path
        d='M119.922 73.2172V61.7582H121.654V73.2172H119.922ZM127.85 73.2172V66.1552C127.85 65.2225 127.583 64.4897 126.984 63.8901C126.384 63.2905 125.718 63.024 124.852 63.024C124.252 63.024 123.719 63.1573 123.253 63.4238C122.787 63.6902 122.387 64.0233 122.12 64.4897C121.854 64.956 121.721 65.489 121.721 66.1552L120.921 65.8221C120.921 64.956 121.121 64.1566 121.521 63.557C121.921 62.8908 122.454 62.4244 123.053 62.0247C123.719 61.6916 124.452 61.4917 125.318 61.4917C126.118 61.4917 126.851 61.6916 127.517 62.0247C128.183 62.3578 128.649 62.8908 129.049 63.557C129.449 64.2232 129.649 64.956 129.649 65.8887V73.2172H127.85ZM135.711 73.2172V66.1552C135.711 65.2225 135.445 64.4897 134.845 63.8901C134.246 63.2905 133.579 63.024 132.647 63.024C132.047 63.024 131.581 63.1573 131.048 63.4238C130.581 63.6902 130.182 64.0233 129.915 64.4897C129.649 64.956 129.515 65.489 129.515 66.1552L128.516 65.8221C128.583 64.956 128.783 64.1566 129.182 63.557C129.582 62.8908 130.182 62.4244 130.848 62.0247C131.514 61.6916 132.247 61.4917 133.113 61.4917C133.912 61.4917 134.645 61.6916 135.312 62.0247C135.978 62.3578 136.511 62.8908 136.844 63.557C137.244 64.2232 137.443 64.956 137.443 65.8887V73.2172H135.711Z'
        fill='#0D1D96'
      />
      <path
        d='M146.106 73.4839C144.973 73.4839 143.907 73.2174 142.975 72.6845C142.042 72.1515 141.309 71.4186 140.776 70.5526C140.243 69.6198 139.977 68.6205 139.977 67.4879C139.977 66.3554 140.243 65.356 140.776 64.49C141.309 63.5572 141.975 62.891 142.908 62.3581C143.774 61.8251 144.773 61.5586 145.906 61.5586C146.972 61.5586 147.838 61.8251 148.637 62.2914C149.437 62.7578 150.103 63.424 150.569 64.2901C151.036 65.0896 151.302 66.0889 151.302 67.1548C151.302 67.2881 151.302 67.4213 151.302 67.6212C151.302 67.7544 151.236 67.9543 151.236 68.2208H141.309V66.6219H150.303L149.703 67.1548C149.703 66.2887 149.57 65.5559 149.237 64.9563C148.971 64.3567 148.504 63.8904 147.971 63.5573C147.438 63.2241 146.772 63.0243 145.973 63.0243C145.173 63.0243 144.44 63.2241 143.774 63.5573C143.174 63.8904 142.641 64.4233 142.308 65.0896C141.975 65.7558 141.775 66.4886 141.775 67.3547C141.775 68.2208 141.975 69.0203 142.308 69.6865C142.708 70.3527 143.174 70.8857 143.841 71.2188C144.507 71.6185 145.24 71.7518 146.106 71.7518C146.772 71.7518 147.438 71.6185 148.038 71.4186C148.637 71.1522 149.104 70.7524 149.504 70.2861L150.569 71.4186C150.036 72.0849 149.37 72.5512 148.571 72.8843C147.838 73.284 146.972 73.4839 146.106 73.4839Z'
        fill='white'
      />
      <path
        d='M146.106 73.4839C144.973 73.4839 143.907 73.2174 142.975 72.6845C142.042 72.1515 141.309 71.4186 140.776 70.5526C140.243 69.6198 139.977 68.6205 139.977 67.4879C139.977 66.3554 140.243 65.356 140.776 64.49C141.309 63.5572 141.975 62.891 142.908 62.3581C143.774 61.8251 144.773 61.5586 145.906 61.5586C146.972 61.5586 147.838 61.8251 148.637 62.2914C149.437 62.7578 150.103 63.424 150.569 64.2901C151.036 65.0896 151.302 66.0889 151.302 67.1548C151.302 67.2881 151.302 67.4213 151.302 67.6212C151.302 67.7544 151.236 67.9543 151.236 68.2208H141.309V66.6219H150.303L149.703 67.1548C149.703 66.2887 149.57 65.5559 149.237 64.9563C148.971 64.3567 148.504 63.8904 147.971 63.5573C147.438 63.2241 146.772 63.0243 145.973 63.0243C145.173 63.0243 144.44 63.2241 143.774 63.5573C143.174 63.8904 142.641 64.4233 142.308 65.0896C141.975 65.7558 141.775 66.4886 141.775 67.3547C141.775 68.2208 141.975 69.0203 142.308 69.6865C142.708 70.3527 143.174 70.8857 143.841 71.2188C144.507 71.6185 145.24 71.7518 146.106 71.7518C146.772 71.7518 147.438 71.6185 148.038 71.4186C148.637 71.1522 149.104 70.7524 149.504 70.2861L150.569 71.4186C150.036 72.0849 149.37 72.5512 148.571 72.8843C147.838 73.284 146.972 73.4839 146.106 73.4839Z'
        fill='#0D1D96'
      />
      <path
        d='M162.359 73.2172V66.4883C162.359 65.489 162.026 64.6896 161.426 64.0233C160.827 63.3571 160.027 63.0906 159.095 63.0906C158.428 63.0906 157.829 63.2239 157.362 63.557C156.829 63.8235 156.43 64.2232 156.163 64.7562C155.897 65.2892 155.697 65.8221 155.697 66.4883L154.897 66.022C154.897 65.1559 155.097 64.3565 155.497 63.6902C155.897 63.024 156.43 62.491 157.162 62.0913C157.829 61.6916 158.628 61.4917 159.561 61.4917C160.427 61.4917 161.226 61.6916 161.893 62.1579C162.559 62.5576 163.092 63.1573 163.492 63.8901C163.891 64.6229 164.091 65.3558 164.091 66.2219V73.2838H162.359V73.2172ZM154.031 73.2172V61.7582H155.763V73.2172H154.031Z'
        fill='white'
      />
      <path
        d='M162.359 73.2172V66.4883C162.359 65.489 162.026 64.6896 161.426 64.0233C160.827 63.3571 160.027 63.0906 159.095 63.0906C158.428 63.0906 157.829 63.2239 157.362 63.557C156.829 63.8235 156.43 64.2232 156.163 64.7562C155.897 65.2892 155.697 65.8221 155.697 66.4883L154.897 66.022C154.897 65.1559 155.097 64.3565 155.497 63.6902C155.897 63.024 156.43 62.491 157.162 62.0913C157.829 61.6916 158.628 61.4917 159.561 61.4917C160.427 61.4917 161.226 61.6916 161.893 62.1579C162.559 62.5576 163.092 63.1573 163.492 63.8901C163.891 64.6229 164.091 65.3558 164.091 66.2219V73.2838H162.359V73.2172ZM154.031 73.2172V61.7582H155.763V73.2172H154.031Z'
        fill='#0D1D96'
      />
      <path
        d='M172.751 73.4837C172.085 73.4837 171.486 73.3504 170.953 73.0839C170.42 72.8174 170.02 72.3511 169.687 71.8181C169.354 71.2851 169.22 70.6189 169.22 69.8194V56.9614H170.953V69.7528C170.953 70.4191 171.152 70.952 171.486 71.3518C171.819 71.6849 172.285 71.8847 172.885 71.8847C173.284 71.8847 173.617 71.8181 173.884 71.6849C174.15 71.5516 174.484 71.3518 174.683 71.0853L175.749 72.1512C175.35 72.6176 174.95 72.9507 174.484 73.1505C174.017 73.3504 173.418 73.4837 172.751 73.4837ZM166.289 63.3571V61.7582H175.35V63.3571H166.289Z'
        fill='white'
      />
      <path
        d='M172.751 73.4837C172.085 73.4837 171.486 73.3504 170.953 73.0839C170.42 72.8174 170.02 72.3511 169.687 71.8181C169.354 71.2851 169.22 70.6189 169.22 69.8194V56.9614H170.953V69.7528C170.953 70.4191 171.152 70.952 171.486 71.3518C171.819 71.6849 172.285 71.8847 172.885 71.8847C173.284 71.8847 173.617 71.8181 173.884 71.6849C174.15 71.5516 174.484 71.3518 174.683 71.0853L175.749 72.1512C175.35 72.6176 174.95 72.9507 174.484 73.1505C174.017 73.3504 173.418 73.4837 172.751 73.4837ZM166.289 63.3571V61.7582H175.35V63.3571H166.289Z'
        fill='#0D1D96'
      />
      <path
        d='M183.476 73.4836C182.344 73.4836 181.344 73.2172 180.478 72.6842C179.612 72.1512 178.879 71.4184 178.346 70.4857C177.813 69.5529 177.547 68.5536 177.547 67.4211C177.547 66.2885 177.813 65.2892 178.346 64.4231C178.879 63.557 179.612 62.8241 180.478 62.2912C181.411 61.7582 182.41 61.4917 183.476 61.4917C184.609 61.4917 185.608 61.7582 186.474 62.2912C187.34 62.8241 188.073 63.557 188.606 64.4231C189.139 65.2892 189.406 66.2885 189.406 67.4211C189.406 68.5536 189.139 69.5529 188.606 70.4857C188.073 71.3517 187.407 72.0846 186.474 72.6176C185.608 73.2172 184.609 73.4836 183.476 73.4836ZM183.476 71.8181C184.276 71.8181 185.009 71.6182 185.608 71.2851C186.274 70.8854 186.741 70.4191 187.074 69.7528C187.474 69.0866 187.607 68.3538 187.607 67.4877C187.607 66.6882 187.407 65.9554 187.074 65.2892C186.741 64.6229 186.208 64.1566 185.608 63.7569C185.009 63.3571 184.276 63.2239 183.476 63.2239C182.677 63.2239 181.944 63.4238 181.344 63.7569C180.678 64.1566 180.212 64.6229 179.879 65.2892C179.546 65.9554 179.346 66.6882 179.346 67.4877C179.346 68.3538 179.546 69.0866 179.879 69.7528C180.212 70.4191 180.745 70.8854 181.344 71.2851C182.011 71.6182 182.677 71.8181 183.476 71.8181Z'
        fill='white'
      />
      <path
        d='M183.476 73.4836C182.344 73.4836 181.344 73.2172 180.478 72.6842C179.612 72.1512 178.879 71.4184 178.346 70.4857C177.813 69.5529 177.547 68.5536 177.547 67.4211C177.547 66.2885 177.813 65.2892 178.346 64.4231C178.879 63.557 179.612 62.8241 180.478 62.2912C181.411 61.7582 182.41 61.4917 183.476 61.4917C184.609 61.4917 185.608 61.7582 186.474 62.2912C187.34 62.8241 188.073 63.557 188.606 64.4231C189.139 65.2892 189.406 66.2885 189.406 67.4211C189.406 68.5536 189.139 69.5529 188.606 70.4857C188.073 71.3517 187.407 72.0846 186.474 72.6176C185.608 73.2172 184.609 73.4836 183.476 73.4836ZM183.476 71.8181C184.276 71.8181 185.009 71.6182 185.608 71.2851C186.274 70.8854 186.741 70.4191 187.074 69.7528C187.474 69.0866 187.607 68.3538 187.607 67.4877C187.607 66.6882 187.407 65.9554 187.074 65.2892C186.741 64.6229 186.208 64.1566 185.608 63.7569C185.009 63.3571 184.276 63.2239 183.476 63.2239C182.677 63.2239 181.944 63.4238 181.344 63.7569C180.678 64.1566 180.212 64.6229 179.879 65.2892C179.546 65.9554 179.346 66.6882 179.346 67.4877C179.346 68.3538 179.546 69.0866 179.879 69.7528C180.212 70.4191 180.745 70.8854 181.344 71.2851C182.011 71.6182 182.677 71.8181 183.476 71.8181Z'
        fill='#0D1D96'
      />
      <path
        d='M195.804 73.4839C195.204 73.4839 194.605 73.4173 194.005 73.2174C193.405 73.0176 192.939 72.8177 192.473 72.4846C192.006 72.1515 191.607 71.8184 191.273 71.4186L192.339 70.2861C192.806 70.819 193.339 71.2854 193.872 71.5519C194.471 71.8184 195.138 71.9516 195.804 71.9516C196.537 71.9516 197.136 71.8184 197.603 71.4853C198.069 71.2188 198.269 70.7524 198.269 70.2861C198.269 69.8197 198.136 69.42 197.802 69.1535C197.469 68.887 197.07 68.6871 196.603 68.4873C196.137 68.354 195.604 68.1542 195.071 68.0209C194.538 67.8877 194.005 67.6878 193.539 67.4213C193.072 67.1548 192.673 66.8217 192.339 66.422C192.073 66.0223 191.873 65.4227 191.873 64.7565C191.873 64.0902 192.006 63.5573 192.339 63.0909C192.673 62.6246 193.139 62.2248 193.738 61.9583C194.338 61.6918 195.071 61.5586 195.87 61.5586C196.736 61.5586 197.536 61.6918 198.202 62.025C198.935 62.3581 199.468 62.7578 199.868 63.424L198.802 64.49C198.469 64.0236 198.002 63.6905 197.536 63.4906C197.003 63.2241 196.47 63.1575 195.804 63.1575C195.071 63.1575 194.538 63.2908 194.138 63.6239C193.738 63.8904 193.605 64.2901 193.605 64.6898C193.605 65.1562 193.738 65.4893 194.072 65.7558C194.405 66.0223 194.738 66.2221 195.271 66.3554C195.737 66.4886 196.27 66.6885 196.803 66.8217C197.336 66.955 197.869 67.1548 198.335 67.4213C198.802 67.6878 199.201 68.0209 199.535 68.4207C199.868 68.8204 200.001 69.42 200.001 70.1528C200.001 71.1521 199.601 72.0182 198.868 72.6178C198.069 73.1508 197.07 73.4839 195.804 73.4839Z'
        fill='white'
      />
      <path
        d='M195.804 73.4839C195.204 73.4839 194.605 73.4173 194.005 73.2174C193.405 73.0176 192.939 72.8177 192.473 72.4846C192.006 72.1515 191.607 71.8184 191.273 71.4186L192.339 70.2861C192.806 70.819 193.339 71.2854 193.872 71.5519C194.471 71.8184 195.138 71.9516 195.804 71.9516C196.537 71.9516 197.136 71.8184 197.603 71.4853C198.069 71.2188 198.269 70.7524 198.269 70.2861C198.269 69.8197 198.136 69.42 197.802 69.1535C197.469 68.887 197.07 68.6871 196.603 68.4873C196.137 68.354 195.604 68.1542 195.071 68.0209C194.538 67.8877 194.005 67.6878 193.539 67.4213C193.072 67.1548 192.673 66.8217 192.339 66.422C192.073 66.0223 191.873 65.4227 191.873 64.7565C191.873 64.0902 192.006 63.5573 192.339 63.0909C192.673 62.6246 193.139 62.2248 193.738 61.9583C194.338 61.6918 195.071 61.5586 195.87 61.5586C196.736 61.5586 197.536 61.6918 198.202 62.025C198.935 62.3581 199.468 62.7578 199.868 63.424L198.802 64.49C198.469 64.0236 198.002 63.6905 197.536 63.4906C197.003 63.2241 196.47 63.1575 195.804 63.1575C195.071 63.1575 194.538 63.2908 194.138 63.6239C193.738 63.8904 193.605 64.2901 193.605 64.6898C193.605 65.1562 193.738 65.4893 194.072 65.7558C194.405 66.0223 194.738 66.2221 195.271 66.3554C195.737 66.4886 196.27 66.6885 196.803 66.8217C197.336 66.955 197.869 67.1548 198.335 67.4213C198.802 67.6878 199.201 68.0209 199.535 68.4207C199.868 68.8204 200.001 69.42 200.001 70.1528C200.001 71.1521 199.601 72.0182 198.868 72.6178C198.069 73.1508 197.07 73.4839 195.804 73.4839Z'
        fill='#0D1D96'
      />
    </svg>
  )
}

export default BancoGenialSVG
