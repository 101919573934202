import React, { createContext, useState, useEffect, useCallback } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider } from '@material-ui/core'
import {
  IEntity,
  IAuth,
  ICookiesUser,
  ICoreContext,
  ICoreProvider,
  IFunctions,
  IToken,
  ICompany,
} from 'interfaces'
import DefaultEntity from 'app/Default'
import CompanyEntity from 'app/Company'
import EmployeeEntity from 'app/Employee'
import CorbanCEPEntity from 'app/Corban/CEP'
import LegalPersonEntity from 'app/LegalPerson'
import Cookies, { getCookies } from '../CookiesProvider'
import SalesChannelEntity from 'app/SalesChannel'
import { specificRoutes } from 'app/SalesChannel/routes'
import { specificRoutes as specificRoutesCompany } from 'app/Company/routes'
import { COMPANY_SUBMODULES, SALESCHANNEL_SUBMODULES } from 'interfaces/submodules'
import SelfAttendanceEntity from 'app/SelfAttendance'

export const CoreContext = createContext<ICoreContext>({} as ICoreContext)

const cookie = getCookies('CFC') as IAuth
export let getToken: IToken = {
  token: cookie.token_jwt,
  refreshToken: cookie.refresh,
  showModalDebits: cookie.showModalDebits,
  isHiddenButton: cookie.isHiddenButton,
}
export const setToken = (prop: IToken) => {
  getToken = prop
}

let storedEntity = getCookies('CFU') as ICookiesUser
export let getUserFunction = {
  userFunction: storedEntity.userFunction,
}
export const setUserFunction = (userFunction: string) => {
  getUserFunction = {
    userFunction,
  }
}

export const clearCookies = () => {
  Cookies.remove('CFC')
  Cookies.remove('CFU')
}

const getRoute = (subModule: string, selectedEntity: IEntity) => {
  let route = selectedEntity.route
  if (subModule === COMPANY_SUBMODULES.SUPERVISOR) route = specificRoutesCompany['SUPERVISOR']
  if (subModule === SALESCHANNEL_SUBMODULES.CDV) route = specificRoutes['CDCVEICULO']
  if (subModule === SALESCHANNEL_SUBMODULES.CP && process.env.REACT_APP_CLIENT?.includes('ALFA'))
    route = specificRoutes['FGTS_ALFA'] // NOTE:
  if (subModule === SALESCHANNEL_SUBMODULES.CP && process.env.REACT_APP_CLIENT?.includes('PEFISA'))
    route = specificRoutes['FGTS_PEFISA']
  return route
}

const entities = [
  CompanyEntity,
  DefaultEntity,
  EmployeeEntity,
  SalesChannelEntity,
  CorbanCEPEntity,
  LegalPersonEntity,
  SelfAttendanceEntity,
]
const CoreProvider: React.FC<ICoreProvider> = ({ children }) => {
  const storedEntity = getCookies('CFU') as ICookiesUser
  const selectedEntity = entities.find((obj) => obj.name === storedEntity.entityName)
  const selectedFunctions = localStorage.getItem('selectedFunctions')
    ? JSON.parse(localStorage.getItem('selectedFunctions') as string)
    : []
  const selectedCompany = localStorage.getItem('selectedCompany')
    ? (JSON.parse(localStorage.getItem('selectedCompany') as string) as ICompany)
    : ({} as ICompany)
  const [entity, setEntity] = useState<IEntity>(
    selectedEntity
      ? {
          ...selectedEntity,
          funcoes: selectedFunctions,
          module: {
            mainModule: selectedEntity.module?.mainModule!,
            subModule: storedEntity?.subModule,
          },
          company: selectedCompany,
          isCompanyPrivate: selectedCompany.empresa_privada,
          route: getRoute(storedEntity.subModule as string, selectedEntity),
        }
      : DefaultEntity
  )
  const [auth, setAuth] = useState<IAuth>(getCookies('CFC'))
  const [functions, setFunctions] = useState<IFunctions[]>(
    localStorage.getItem('functions')
      ? (JSON.parse(localStorage.getItem('functions') || '') as IFunctions[])
      : []
  )

  const resetEntity = useCallback(() => {
    setEntity(DefaultEntity)
    Cookies.remove('CFU')
    localStorage.clear()
  }, [setEntity])
  const logout = useCallback(() => {
    getToken = {} as IToken
    localStorage.clear()
    clearCookies()
    setAuth({} as IAuth)
    setEntity(DefaultEntity)
  }, [])

  // TODO: Passar para um reducer para não ficar compartilhando multiplos states e sim um dispatch
  const values: ICoreContext = {
    auth,
    entity,
    functions,
    setFunctions,
    setEntity,
    setAuth,
    logout,
    resetEntity,
  }

  useEffect(() => {
    if (auth.token_jwt) {
      getToken = {
        token: auth.token_jwt,
        refreshToken: auth.refresh,
        showModalDebits: auth.showModalDebits,
        isHiddenButton: auth.isHiddenButton,
      } as IToken
    }
    Cookies.set('CFC', auth, { expires: 365, sameSite: 'strict' })
  }, [auth])

  useEffect(() => {
    const cookieUser: ICookiesUser = {
      entityName: entity.name,
      userFunction: entity.funcao?.id,
      subModule: entity.module?.subModule,
    }
    if (cookieUser.userFunction) {
      getUserFunction = {
        userFunction: entity.funcao?.id,
      }
      if (entity.funcoes) localStorage.setItem('selectedFunctions', JSON.stringify(entity.funcoes))
      if (entity.company) localStorage.setItem('selectedCompany', JSON.stringify(entity.company))
      Cookies.set('CFU', cookieUser, { expires: 365, sameSite: 'strict' })
    }
  }, [entity])

  useEffect(() => {
    if (functions.length > 0) {
      localStorage.setItem('functions', JSON.stringify(functions))
    }
  }, [functions])

  return (
    <CoreContext.Provider value={values}>
      <ThemeProvider theme={entity.materialTheme}>
        <StyledThemeProvider theme={entity.theme}>{children}</StyledThemeProvider>
      </ThemeProvider>
    </CoreContext.Provider>
  )
}

export default CoreProvider
