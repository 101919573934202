import { IPermission } from 'interfaces'
import DefaultEntity from 'app/Default'

export const cardAuthRequired: IPermission = {
  validate: function () {
    const { auth } = this

    return !!auth?.isConsignedCard
  },

  action: function () {
    const { history, logout } = this
    if (history && logout) {
      logout()
      history.push(DefaultEntity.path)
    }
  },
}

export default cardAuthRequired
