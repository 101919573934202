import FeedbackEntity from 'app/Feedback'
import { IPermission } from 'interfaces'

export const hasPermission = (permissionName: string): IPermission => ({
  validate: function () {
    const { entity } = this

    if (!entity?.funcao?.id) return true

    const hasPermission = !!(entity?.funcao?.permissoes || []).find(
      (func: string) => func === permissionName
    )

    return hasPermission
  },
  action: function () {
    const { history } = this
    history?.push(FeedbackEntity.route?.NOT_FOUND.path)
  },
})

export default hasPermission
