import * as React from 'react'

function LogoFull() {
  return (
    <svg width='225px' viewBox='0 0 150 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 0V14H3.11778V4.8L11.2587 14H14.2898L22.6039 4.8V14H25.5485V0H22.6039L12.8176 11.44L3.11778 0H0Z'
        fill='#451CDC'
      />
      <path d='M50.0577 2.64V0H71.709V2.64H62.4423V14H59.2379V2.64H50.0577Z' fill='#451CDC' />
      <path
        d='M74.567 14V0H95.612V2.48H77.5982V5.92H94.1397V8.32H77.5982V11.6H95.612V14H74.567Z'
        fill='#451CDC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.4538 14H30.2252L32.7367 11.2H48.5854L51.1836 14H53.8684V13.04L42.1767 0H39.0589L27.4538 13.04V14ZM46.4203 8.72H34.9018L40.7044 2.32L46.4203 8.72Z'
        fill='#451CDC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.47 0V14H101.674V9.76H115.531L118.736 14H122.113L118.562 9.36C119.63 9.12 121.767 7.888 121.767 4.88C121.767 1.12 118.476 0 116.484 0H98.47ZM101.674 7.2V2.48L116.484 2.64C117.234 2.69333 118.736 3.216 118.736 4.88C118.736 6.544 117.234 7.12 116.484 7.2H101.674Z'
        fill='#451CDC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M123.585 14H126.357L128.868 11.2H144.717L147.315 14H150V13.04L138.308 0H135.191L123.585 13.04V14ZM142.552 8.72H131.033L136.836 2.32L142.552 8.72Z'
        fill='#451CDC'
      />
    </svg>
  )
}

export default LogoFull
