import { IEntity, ENTITIES, ICompany } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.EMPLOYEE)
export const FeedbackEntity: IEntity = {
  id: ENTITIES.FEEDBACK,
  name: 'Feedback',
  path: '',
  theme,
  materialTheme,
  logo,
  permissions: [],
  component: DefaultComponent,
  route: Routes,
  company: {} as ICompany,
}

export default FeedbackEntity
